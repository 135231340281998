.sv-rating {
  color: var(--text-color, $text-color);
  padding-bottom: 3px;
  input:focus + .sv-rating__min-text + .sv-rating__item-text,
  input:focus + .sv-rating__item-text {
    outline: 1px solid var(--main-color, $main-color);
    outline-offset: 2px;
  }
}

.sv-rating__item {
  position: relative;
  display: inline;
}

.sv-rating__item-text {
  min-width: 2.3125em;
  height: 2.3125em;
  display: inline-block;
  color: var(--main-hover-color, $main-hover-color);
  padding: 0 0.3125em;
  border: solid 0.1875em var(--main-hover-color, $main-hover-color);
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.13;
  cursor: pointer;
  margin: 3px 0;
  margin-right: 0.26em;
  box-sizing: border-box;

  & > span {
    margin-top: 0.44em;
    display: inline-block;
  }

  &:hover {
    background-color: var(--main-hover-color, $main-hover-color);
    color: var(--body-background-color, $body-background-color);
  }
}

.sv-rating__item--selected {
  .sv-rating__item-text {
    background-color: var(--main-color, $main-color);
    color: var(--body-background-color, $body-background-color);
    border-color: var(--main-color, $main-color);

    &:hover {
      background-color: var(--main-color, $main-color);
    }
  }
}

.sv-rating__item-star > svg {
  fill: var(--text-color, $text-color);
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--main-hover-color, $main-hover-color);
  }
  &.sv-star-2 {
    display: none;
  }
}

.sv-rating__item-star--selected>svg {
  fill: var(--main-color, $main-color);
}

.sv-rating__item-smiley>svg {
  height: 24px;
  width: 24px;
  padding: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 3px solid var(--border-color, $border-color);
  margin: 3px 0;
  margin-right: 0.26em;
  fill: var(--main-hover-color, $main-hover-color);

  &>use {
    display: block;
  }

  &:hover {
    border: 3px solid var(--main-hover-color, $main-hover-color);
    background-color: var(--main-hover-color, $main-hover-color);
  }
}

.sv-rating__item-smiley--selected>svg {
  background-color: var(--main-color, $main-color);
  fill: var(--body-background-color, $body-background-color);
  border: 3px solid var(--main-color, $main-color);
}
.sv-rating__min-text {
  font-size: 1em;
  margin-right: 1.25em;
  cursor: pointer;
}

.sv-rating__max-text {
  font-size: 1em;
  margin-left: 0.87em;
  cursor: pointer;
}

.sv-question--disabled {
  .sv-rating__item-text {
    cursor: default;
    color: var(--disable-color, $disable-color);
    border-color: var(--disable-color, $disable-color);
    &:hover {
      background-color: transparent;
    }
  }

  .sv-rating--disabled .sv-rating__item-text:hover .sv-rating__item--selected .sv-rating__item-text {
    background-color: var(--disable-color, $disable-color);
    color: var(--body-background-color, $body-background-color);
  }

  .sv-rating__item--selected {
    .sv-rating__item-text {
      background-color: var(--disable-color, $disable-color);
      color: var(--body-background-color, $body-background-color);
    }
  }

  .sv-rating__min-text {
    cursor: default;
  }

  .sv-rating__max-text {
    cursor: default;
  }
}
