.sv-container-modern {
  color: var(--text-color, $text-color);
  font-size: var(--font-size, $font-size);
  font-family: $font-family;
}

.sv-container-modern__title {
  color: var(--main-color, $main-color);
  padding-left: 0.55em;
  padding-top: 5em;
  padding-bottom: 0.9375em;

  @media only screen and (min-width: 1000px) {
    margin-right: 5%;
    margin-left: 5%;
  }

  @media only screen and (max-width: 1000px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-container-modern__title h3 {
  margin: 0;
  font-size: 1.875em;
}

.sv-container-modern__title h5 {
  margin: 0;
}

.sv-container-modern__close {
  clear: right;
}

.sv-container-modern fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.sv-container-modern legend {
  border: none;
  padding: 0;
  margin: 0;
}